.experience, .education {

  .item {
    margin-bottom: 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid $lighter-blue;

    &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
      margin-bottom: 0;
    }
    
    .title {
      font-size: 16px;
      margin-top: 0;
      margin-bottom: 5px;
      line-height: 1.5;

      .grade {
        white-space: nowrap;
      }

     .place {
        color: $gray-600;
        font-weight: normal;
        font-size: 14px;

        a {
          color: $gray-600;

          &:hover {
            color: $gray-800;
          }
        }
      }
    }

    .sub-title {
      text-align: right;
      .year {
        color: $gray-700;
        font-weight: normal;
        font-size: 14px;
      }
  
      .contract-type {
        color: $gray-600;
        font-weight: normal;
        font-size: 13px;
        font-style: italic;
      }
    }

    ul.details {
      padding-left: 30px;
      margin-top: 4px;
      margin-bottom: 8px;
      li {
        font-size: 14px;
        line-height: 23px;
      }
    }

    .tech-stack {
      margin-left: 10px;
    }
  }
}

@media (max-width: 767.98px) {
  .experience, .education {
    .item {
      .sub-title {
        .year {
          color: $text-color;
          font-weight: 500;
        }
        text-align: left;
        margin-bottom: 10px;
      }
    }
  }
}