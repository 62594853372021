body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
  color: $gray-700;
}

a {
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
}

a:hover {
  text-decoration: underline;
}

a:focus {
  text-decoration: none;
}

.btn, a.btn {
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  padding: 8px 16px;
  font-weight: bold;

  &:disabled, &.disabled {
    cursor: not-allowed;
  }

  &.btn-primary {
    color: #fff;
  }
  &.btn-light {
    font-weight: 400;
  }
}

.btn .svg-inline--fa, a.btn .svg-inline--fa {
  margin-right: 5px;
  position: relative;
  top: -2px;
}

.btn:focus, a.btn:focus {
  color: #fff;
}

.text-highlight {
  color: #32383e;
}

.badge-theme {
  background: #3AAA64;
  font-size: 12px;
  color: #fff;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}

a.dotted-link {
  border-bottom: 1px dotted #778492;
  color: #778492;
}

a.dotted-link:hover {
  text-decoration: none;
  color: #49515a;
}

.badge {
  margin-right: 2px;
  padding-top: 0.35em;
  padding-bottom: 0.35em;

  &.badge-light {
    background-color: $lighter-blue;
  }
}

button.href-link {
  padding: 0;
  margin: 0;
  border: 0;
  background: none;
  display: inline;
  color: $link-color;

  &:hover {
    text-decoration: underline;
  }

}

/* ======= Sections======= */
.sections-wrapper {
  padding-top: 60px;
  padding-bottom: 60px;
}

.section {
  margin-bottom: 30px;
}

.section .section-inner {
  background: #fff;
  border: 0px solid #e5e5e4;
  padding: 30px;

  p:last-child {
    margin-bottom: 0;
  }
}

.section .heading {
  margin-top: 0;
  margin-bottom: 30px;
  color: $gray-700;
  font-size: 24px;
}

.section .content .more-link .svg-inline--fa {
  margin-right: 5px;
  font-size: 14px;
  position: relative;
  top: -2px;
}

/* About Section */
/* Latest Section */
.latest .item {
  margin-bottom: 30px;
}

.latest .item .title {
  font-size: 18px;
  margin-top: 0;
}

.latest .item .title .label {
  margin-left: 5px;
  font-size: 12px;
}

.latest .item .title a {
  color: #778492;
}

.latest .item .title a:hover {
  color: #5f6b77;
}

.latest .item .project-image:hover {
  -webkit-opacity: 0.8;
  -moz-opacity: 0.8;
  opacity: 0.8;
}

.latest .divider {
  margin-bottom: 60px;
}

.latest .featured {
  margin-bottom: 60px;
}

.latest .featured .title {
  font-size: 20px;
  margin-bottom: 5px;
  font-size: 20px;
}

.latest .featured .summary {
  margin-bottom: 30px;
  color: #778492;
}

.latest .featured img {
  margin-bottom: 30px;
}

.latest .featured .desc {
  margin-bottom: 30px;
}

.latest .has-ribbon {
  position: relative;
  display: inline-block;
}

.latest .has-ribbon .text {
  background: #3AAA64;
  color: #fff;
}

.latest .has-ribbon .ribbon {
  position: absolute;
  top: -4px;
  right: -4px;
  width: 110px;
  height: 110px;
  overflow: hidden;
}

.latest .has-ribbon .ribbon .text {
  position: relative;
  left: -8px;
  top: 18px;
  width: 158px;
  padding: 10px 10px;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  background-color: #479FC8;
  transform: rotate(45deg) translate3d(0, 0, 0);
  -webkit-transform: rotate(45deg) translate3d(0, 0, 0);
  -moz-transform: rotate(45deg) translate3d(0, 0, 0);
  -ms-transform: rotate(45deg) translate3d(0, 0, 0);
  -o-transform: rotate(45deg) translate3d(0, 0, 0);
}

.latest .has-ribbon .ribbon .text:before, .latest .has-ribbon .ribbon .text:after {
  content: '';
  position: absolute;
  bottom: -5px;
  border-top: 5px solid #276582;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}

.latest .has-ribbon .ribbon .text:before {
  left: 0;
}

.latest .has-ribbon .ribbon .text:after {
  right: 0;
}

/* List section */
.list ul li {
  margin-bottom: 10px;
}

.list ul li .svg-inline--fa {
  margin-right: 5px;
}

.list ul li a {
  color: #778492;
}

.list ul li a:hover {
  color: #49515a;
}

/* Credits */
.credits ul li {
  margin-bottom: 10px;
}

.credits ul li .svg-inline--fa {
  margin-right: 5px;
  position: relative;
  top: -2px;
}

.credits ul li a {
  color: #778492;
}

.credits ul li a .svg-inline--fa {
  color: #b0b7bf;
}

.credits ul li a:hover {
  color: #49515a;
}

.credits .btn {
  margin-bottom: 15px;
}

.aside .subheading {
  font-size: 18px;
  color: #545e69;
}

.language-links {
  height: 22px;
  .language-link {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 10.5px;
  }
  span {
    color: #fff;
    font-weight: 400;
  }
}

@media (min-width: 1400px) {
  .container {
    width: 1360px;
    max-width: inherit;
  }
}

@media (max-width: 767.98px) {
  .sections-wrapper {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  
  .section .section-inner {
    padding: 25px 20px;
  }
}

@media (max-width: 991.98px) {
  .secondary {
    display: flex;
    flex-direction: column;
    #aside1 {
      order: 3;
    }
    #aside2 {
      order: 2;
    }
    #aside3 {
      order: 1;
    }
  }
}