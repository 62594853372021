.testimonials {
  .item {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }

    .quote {
      color: #666;
      font-size: 14px;
      border-left: 5px solid $light-blue;
      padding: 0px 15px;
      margin-bottom: 15px;

      p {
        margin-bottom: 0;
      }

      .svg-inline--fa {
        color: $light-blue;
        margin-right: 15px;
      }
    }

    .source {
      font-size: 14px;
      padding-left: 20px;
      font-weight: 500;

      .name {
        color: #939ea9;
        font-weight: 600;
      }

      .title {
        color: #999;

        a {
          color: #999;
        }
      }
    }
  }
}

@media(max-width: 767.98px) {
  .testimonials {
    .item {
      .quote {
        margin-bottom: 5px;
      }
    }
  }
}