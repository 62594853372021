// My variables
$lighter-blue: #e4f0f8;
$light-blue: #acd3eb;
$normal-blue: #4699d1;
$dark-blue: #256d9b;

// Customized bootstrap variables
$font-size-base: 0.9375rem; // 15/16
$body-bg: #f4f8fc;
$text-color: #404141;
$primary: #f38020;
$secondary: #1f567a;
$success: $normal-blue;
$tooltip-font-size: 12px;

$input-font-size: 14px;
$input-btn-font-size: 14px;
$input-border-color: $light-blue;

// Imports
@import '~bootstrap/scss/bootstrap.scss';
@import 'App.scss';
@import 'Experience.scss';
@import 'Forms.scss';
@import 'Header.scss';
@import 'ContactInfo.scss';
@import 'MobileMenu.scss';
@import 'Modals.scss';
@import 'Languages.scss';
@import 'Skills.scss';
@import 'Testimonials.scss';
@import 'Footer.scss';
