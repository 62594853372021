.footer {
  background: #323232;
  color: #fff;
  padding: 10px 0;

  .copyright {
    line-height: 1.6;
    color: #fff;
    font-size: 12px;
  }

  a {
    color: #fff;
  }

  .fa-heart {
    color: $primary;
  }
}
