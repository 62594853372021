.skills {
  .intro {
    margin-bottom: 30px;
    color: $gray-600;
  }

  .skillset {
    .item {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
    .skill-heading {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
      h4.skill-title {
        font-size: 14px;
        margin: 0;
      }
      h4.skill-years {
        white-space: nowrap;
        margin: 0;
        margin-left: 5px;
        font-size: 14px;
        color: $gray-700;
        font-weight: 400;
      }
    }
    .tech {
      margin: 0 10px;
    }
  }

  .svg-inline--fa {
    margin-right: 3px;
    position: relative;
    top: -1px;
  }
}
