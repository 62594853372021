/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: $primary;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: darken($primary, 5%);
  opacity: 1!important;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: $primary;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: $secondary;
  padding: 2.5em 1.5em 0 0.5em;
  font-size: 1.15em;
  position: relative;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 1.2em 0.8em 0;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

/* Custom */
$menu-item-gap: 10px;

.menu-item {
  font-size: 20px;
  font-weight: 500;
  margin: $menu-item-gap 0;
  text-decoration: none !important;

  .language-links {
    height: 34px;
    position: absolute;
    top: 5px;
    display: block;
    width: 264px;
    padding-bottom: 3px;
    border-bottom: 1px solid $dark-blue;

    .language-link {
      font-size: 12px;
    }
  }
  
  .separator-dl {
    display: inline-block;
    border-top: 1px solid $dark-blue;
    margin-top: 10px;
    padding-top: 15px;
    a {
      font-size: 15px;
    }
  }
}

